// const addressFields = [
//   // 'addressLine1',
//   // 'addressLine2',
//   // 'addressLine3',
//   // 'addressLine4',
//   // 'ward',
//   'nuts',
//   'city',
//   'postalCode'
// ]

export default (user) => {
  const fields = []
  if (user.city) { fields.push(user.city) } else if (user.nuts) { fields.push(user.nuts) } else { fields.push('-') }
  fields.push(user.postalCode)
  return fields
}
