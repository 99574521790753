<template>
<v-container v-if="retailers" fluid>
    <v-card outlined>
      <v-card-title>
        Find retailers for {{artist.name}}
      </v-card-title>
    </v-card>
    <v-card outlined>
    <v-row>
      <v-col cols="3" style="height:100%">
          <v-list class="mt-4" two-line>
            <v-list-item>
              <v-list-item-content>
                <v-text-field v-model="postCode" label="Post Code" dense outlined clearable @click:clear="clearSearch"
                  @input="updateSearch">
                </v-text-field>
                <v-list-item-subtitle>
                  Showing {{ filteredRetailers.length}} retailers
                </v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
          </v-list>
            <!-- <v-list-item-group v-model="selected" @change="handleSelect" active-class="blue--text"> -->
          <v-virtual-scroll :items="filteredRetailers" :item-height="70" height="600">
            <template v-slot="{ index, item }">
              <v-list-item style="border-bottom: 1px solid grey" @change="handleSelect(index)" active-class="blue--text">
                <template v-slot:default="{ active }">
                  <v-list-item-content class="pt-2">
                    <v-list-item-title v-text="item.retailer"></v-list-item-title>
                    <v-list-item-subtitle v-for="(line, index) in getAddress(item)" :key="index" class="text--primary"
                      v-text="line"></v-list-item-subtitle>
                  </v-list-item-content>
                  <v-list-item-action>
                    <v-list-item-action-text @click.stop="showResellerDetails(item)" v-text="index === selected ? 'Details' : null">
                    </v-list-item-action-text>
                    <v-icon :color="active ? 'yellow darken-3' : 'grey lighten-1'">
                      mdi-star-outline
                    </v-icon>
                  </v-list-item-action>
                </template>
              </v-list-item>
            </template>
          </v-virtual-scroll>
      </v-col>
      <v-col cols="9">
      <v-alert v-if="!Object.keys(filteredRetailers).length" type="info">
        <p>No data to display.</p>
      </v-alert>
      <Retailer v-if="retailerDetail" :retailer="retailers[selected]" @close="closeResellerDetails" />
      <v-card v-if="!retailerDetail" ref="mapContainer" style="height: calc(100vh - 160px); display:flex;flex-direction:column;align-items:center;justify-content:center;">
      <l-map ref="map" style="height: 100%;z-index:1" :zoom="zoom" :center="center">
        <l-tile-layer :url="url" :attribution="attribution"></l-tile-layer>
          <l-feature-group @ready="onReady" ref="features">
          <template v-for='(retailer, index) of filteredRetailers'>
            <l-marker @click="scrollTo(index)" :key='index' v-if="retailer.latitude" :lat-lng="[retailer.latitude, retailer.longitude]">
              <l-icon
                :icon-size="selected === index ? [40, 40] : [20,20]"
                :icon-url="selected === index ? greenIcon : redIcon"
              />
              <l-popup :content="retailer.retailer"></l-popup>
            </l-marker>
          </template>
          </l-feature-group>
      </l-map>
      </v-card>
      </v-col>
      </v-row>
    </v-card>
  </v-container>
</template>

<script>
import { LMap, LTileLayer, LFeatureGroup, LMarker, LPopup, LIcon } from 'vue2-leaflet'
import axios from 'axios'
import isPointWithinRadius from 'geolib/es/isPointWithinRadius'
import formatAddress from '@/utilities/formatAddress'
import Retailer from '@/components/RetailerDetail'

export default {
  props: {
    artist: Object,
    retailers: Array
  },
  components: {
    LMap,
    LTileLayer,
    LFeatureGroup,
    LMarker,
    LPopup,
    LIcon,
    Retailer
  },
  data () {
    return {
      greenIcon: 'https://raw.githubusercontent.com/pointhi/leaflet-color-markers/master/img/marker-icon-2x-green.png',
      redIcon: 'https://raw.githubusercontent.com/pointhi/leaflet-color-markers/master/img/marker-icon-2x-red.png',
      mapOverlay: true,
      url: 'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png',
      attribution:
        '&copy; <a target="_blank" href="http://osm.org/copyright">OpenStreetMap</a> contributors',
      zoom: 6,
      center: [55.94736446069031, -3.1921626077463103],
      markerLatLng: [51.504, -0.159],
      imageUrl: process.env.VUE_APP_IMG_URL,
      iconSize: 40,
      retailerDetail: false,
      loaded: true,
      postCode: null,
      selected: null,
      filteredRetailers: this.retailers
    }
  },
  methods: {
    scrollTo (item) {
      this.selected = item
      document.getElementById('item-' + item).scrollIntoView()
    },
    getMapTop () {
      return this.$refs.mapContainer && this.$refs.mapContainer.$el.getBoundingClientRect().top
    },
    onResize () {
      this.$refs.map[0].mapObject._onResize()
    },
    onReady () {
      const bnds = this.$refs.features && this.$refs.features.mapObject.getBounds()
      this.$refs.map.fitBounds(bnds)
    },
    getAddress (item) {
      return formatAddress(item)
    },
    showResellerDetails (item) {
      this.retailerDetail = true
    },
    closeResellerDetails (item) {
      this.retailerDetail = false
    },
    handleSelect (item) {
      this.retailerDetail = false
    },
    async clearSearch () {
      this.filteredRetailers = [].concat(this.retailers)
    },
    async updateSearch (item) {
      if (item === '') {
        this.filteredRetailers = [].concat(this.retailers)
        return true
      }
      try {
        const geo = await axios.get(`https://api.postcodes.io/outcodes/${item}`)
        const centre = { latitude: geo.data.result.latitude, longitude: geo.data.result.longitude }
        this.filteredRetailers = this.retailers.filter(retailer => {
          try {
            const nearby = isPointWithinRadius(
              { latitude: retailer.latitude, longitude: retailer.longitude },
              centre,
              10000
            )
            return nearby ? retailer : null
          } catch (e) { console.log('e') }
        })
      } catch (e) {
        // console.log('nf', e)
      }
    }
  },
  watch: {
    async filteredRetailers (newRetailers, oldRetailers) {
      const bnds = await this.$refs.features && this.$refs.features.mapObject.getBounds()
      bnds && this.$refs.map && this.$refs.map.fitBounds(bnds)
    }
  },
  computed: {
    dynamicSize () {
      return [this.iconSize, this.iconSize * 1.15]
    },
    dynamicAnchor () {
      return [this.iconSize / 2, this.iconSize * 1.15]
    }
  }
}
</script>
<style lang="scss">
  .cluster-marker {
    border: 1px solid red;
    background-color: yellow;
    width: 100%;
    height: 100%;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
</style>
